<template>
  <div>
    <!-- first card -->
    <b-card
      class="mb-1 compact_page_card_title"
      :title="$t('security_creation.titles.security_creation')"
    >
      <hr />

      <b-row class="mb-2 justify-content-start align-items-center">
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          class="list-pages-buttons"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-info"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="getAllEntries"
          >
            <feather-icon
              icon="BoldIcon"
              size="16"
              style="margin-bottom: 0.3rem;"
            />
            <span class="text-nowrap">{{
              $t("security_creation.buttons.bring_all")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          v-if="$Can('search_account')"
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          class="list-pages-buttons"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-dark"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="verifiedEntries"
          >
            <b-icon
              icon="patch-check"
              font-scale="1"
              style="margin-bottom: 0.3rem;"
            >
            </b-icon>
            <span class="text-nowrap">{{
              $t("security_creation.buttons.bring_verified_entries")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          class="list-pages-buttons"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="flat-secondary"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="unverifiedEntries"
          >
            <b-icon
              icon="patch-question"
              font-scale="1"
              style="margin-bottom: 0.3rem;"
            >
            </b-icon>
            <span class="text-nowrap">{{
              $t("security_creation.buttons.bring_unverified_entries")
            }}</span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          xl="auto"
          lg="auto"
          md="3"
          sm="6"
          class="list-pages-buttons"
          style="display:flex;justify-content:center;align-items:center"
        >
          <b-button
            v-if="$Can('add_security')"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="gradient-success"
            size="sm"
            class="compact_nav_icon-text-button"
            @click="createSecurityModal"
          >
            <feather-icon
              icon="PlusIcon"
              size="14"
              style="margin-bottom: 0.3rem;"
            />
            <span class="text-nowrap">{{
              $t("security_creation.buttons.new_entry")
            }}</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- second card -->
    <b-card>
      <b-overlay :show="loading" no-fade>
        <div
          @contextmenu.prevent="$refs.contextMenu.open"
          v-if="allSecurityData.length > 0"
        >
          <b-table
            hover
            ref="refSecurityCreationTable"
            :bordered="true"
            :items="filteredTrades"
            :filter="filter"
            filter-debounce="250"
            @filtered="onFiltered"
            :filter-included-fields="filterOn"
            selectable
            select-mode="single"
            style="max-height: 95vh !important"
            class="position-relative compact_table"
            :small="true"
            responsive
            head-variant="dark"
            :fields="tableColumns"
            show-empty
            :empty-text="
              $t('security_creation.messages.no_matching_security_found')
            "
            :current-page="currentPage"
            :per-page="perPage"
            caption-top
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortByDesc"
            :tbody-tr-class="legendClass"
          >
            <!-- :tbody-tr-class="legendClass" -->
            <!-- @row-clicked="onRowSelected" -->

            <!-- Column: column filter -->
            <template
              slot="top-row"
              slot-scope="{ fields }"
              v-if="showColumnsFilter"
            >
              <td
                :class="field.key == 'id' ? 'sticky-column' : ''"
                v-for="field in tableColumns"
                :key="field.key"
              >
                <v-select
                  v-if="field.key == 'SecurityType'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="
                    field.key + ' column_search_input compact_form_vue-select'
                  "
                  :options="securityTypes"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'SecurityCcy'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="
                    field.key + ' column_search_input compact_form_vue-select'
                  "
                  :options="allCurrencies"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />
                <v-select
                  v-else-if="field.key == 'status'"
                  label="title"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="
                    field.key + ' column_search_input compact_form_vue-select'
                  "
                  :options="optStatuses"
                  :clearable="false"
                  :reduce="(val) => val.value"
                />

                <input
                  v-else-if="field.key == 'ID'"
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="'ID_column_search_input compact_form-input'"
                  style="max-width: 75px !important; "
                />

                <input
                  v-else
                  v-model="filters[field.key]"
                  :placeholder="field.label"
                  :class="
                    field.key + ' column_search_input compact_form_vue-select'
                  "
                />
                <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
              </td>
            </template>

            <!-- Column: check box -->
            <template #cell(id)="data">
              <div class="d-flex justify-content-center align-items-center">
                <b
                  ><span class="font-weight-bold">
                    <strong class="text-primary">#{{ data.item.id }}</strong>
                  </span></b
                >
              </div>

              <div
                v-if="!showMultiplecheckboks && filteredTrades.length > 0"
                class="d-flex justify-content-center align-items-center compact_controls_button"
              >
                <b-button
                  :id="
                    'trade-controls-' + data.item ? data.item.id.toString() : ''
                  "
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns animate__animated animate__fadeIn"
                  @click="
                    showTradeControlsPopover(
                      'trade-controls-' + data.item
                        ? data.item.id.toString()
                        : '',
                      data.item
                    )
                  "
                >
                  <i class="fa-solid fa-bars"></i>
                </b-button>
                <b-popover
                  :target="
                    'trade-controls-' + data.item ? data.item.id.toString() : ''
                  "
                  triggers="click blur"
                  variant="primary"
                >
                  <template #title>
                    {{ $t("equity_bond.titles.trade_controls") }}
                  </template>

                  <b-button
                    v-if="$Can('verify_security') && seeVerifyButton"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-success"
                    size="sm"
                    class="top-btns mt-1"
                    @click="verifySelectedAccount(data.item)"
                  >
                    <span class="trades-font">
                      <feather-icon
                        icon="CheckSquareIcon"
                        style="margin-right: 0.3rem;"
                      />{{
                        $t("security_creation.buttons.verify_security")
                      }}</span
                    >
                  </b-button>

                  <b-button
                    v-if="$Can('amend_security')"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-warning"
                    size="sm"
                    class="top-btns mt-1"
                    @click="amendEntry(data.item)"
                  >
                    <span class="trades-font">
                      <feather-icon
                        icon="EditIcon"
                        style="margin-right: 0.3rem;"
                      />{{ $t("security_creation.buttons.amend_entry") }}</span
                    >
                  </b-button>

                  <b-button
                    v-if="data.item.SecurityType == 'Bond'"
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="outline-info"
                    size="sm"
                    class="top-btns mt-1"
                    @click="viewPaymentSchedule(data.item)"
                  >
                    <span class="trades-font">
                      <feather-icon
                        icon="CalendarIcon"
                        style="margin-right: 0.3rem;"
                      />
                      Payment Schedule
                    </span>
                  </b-button>
                </b-popover>
              </div>
              <div
                v-if="showMultiplecheckboks"
                class="d-flex justify-content-center align-items-center compact_controls_button"
              >
                <b-form-checkbox
                  v-model="data.item.selectedTrade"
                  @change="multiSelectRow(data.item.selectedTrade, data.item)"
                  plain
                  class="animate__animated animate__fadeIn"
                >
                </b-form-checkbox>
              </div>
            </template>

            <!-- Column: Status -->
            <template #cell(status)="data">
              <b-badge
                v-if="data.value != true"
                pill
                :variant="`light-success`"
                class="text-capitalize"
              >
                {{ $t("security_creation.text.verified") }}
              </b-badge>

              <b-badge
                v-else
                pill
                :variant="`light-danger`"
                class="text-capitalize"
              >
                {{ $t("security_creation.text.not_verified") }}
              </b-badge>
            </template>

            <!-- Column: SecurityType -->
            <template #cell(SecurityType)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SecurityType ? data.item.SecurityType : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SecurityTicker -->
            <template #cell(SecurityTicker)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SecurityTicker ? data.item.SecurityTicker : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SecurityIsin -->
            <template #cell(SecurityIsin)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SecurityIsin ? data.item.SecurityIsin : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: SecurityCcy -->
            <template #cell(SecurityCcy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.SecurityCcy ? data.item.SecurityCcy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <!-- Column: CreatedBy -->
            <template #cell(CreatedBy)="data">
              <b
                ><span class="font-weight-bold">
                  <strong>{{
                    data.item.CreatedBy ? data.item.CreatedBy : "-"
                  }}</strong>
                </span></b
              >
            </template>

            <template #cell(CreationDate)="data">
              <span>{{ dateFormat(data.value) }}</span>
            </template>

            <template #cell(VerificationDate)="data">
              <span>{{ dateFormat(data.value) }}</span>
            </template>

            <template #cell(CreationTime)="data">
              <span>{{ clockFormat(data.value) }}</span>
            </template>

            <template #cell(VerificationTime)="data">
              <span>{{ clockFormat(data.value) }}</span>
            </template>

            <template #cell(VerifiedBy)="data">
              <span>{{ data.value ? data.value : "-" }}</span>
            </template>
          </b-table>
        </div>

        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkbox"
                  : "Hide multiple checkbox"
              }}</span>
            </b-link>
          </li>
          <li v-if="selectedMultipleTrades.length > 0">
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon icon="CopyIcon" size="16" />
              <span class="ml-75">{{
                $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>
        <div class="mx-2 mb-2" v-if="allSecurityData.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.multiple_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(SecurityIsin)="data">
                  <strong>{{ data.item.SecurityIsin }}</strong>
                </template>
                <template #cell(info)="data">
                  <b-badge
                    v-if="data.item.info == 'Pass'"
                    pill
                    :variant="`light-success`"
                    class="text-capitalize"
                    style="background-color:rgba(255, 255, 255, 0.5)"
                  >
                    {{ data.item.info }}
                  </b-badge>

                  <b-badge
                    v-else
                    pill
                    :variant="`light-danger`"
                    class="text-capitalize"
                    style="background-color:rgba(255, 255, 255, 0.5)"
                  >
                    {{ data.item.info }}
                  </b-badge>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>

    {{ /*security create modal*/ }}
    <b-modal
      id="securityModal"
      v-model="securityCreateModal"
      :title="$t('security_creation.titles.security_entry_screen')"
      @hidden="resetSecurityModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('security_creation.buttons.create')"
      :cancel-title="$t('security_creation.buttons.cancel')"
      @ok="createSecurity"
    >
      <b-overlay :show="SecurityModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-2" style="justify-content: center">
                <b-col cols="12" xl="6" lg="6" md="12" sm="12">
                  <b-card
                    :title="$t('security_creation.titles.security_details')"
                    class="compact_card_title"
                  >
                    <b-row>
                      <!-- SecurityType-->
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.security_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.security_type'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="securityData.SecurityType"
                              :label="
                                $t(
                                  'security_creation.security_form.security_isin'
                                )
                              "
                              id="securityType"
                              :state="errors.length > 0 ? false : null"
                              :clearable="false"
                              :options="optSecurityTypes"
                              @change="
                                changeSecurityType(securityData.SecurityType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <!-- security ticker-->
                        <b-form-group
                          :label="
                            $t(
                              'security_creation.security_form.security_ticker'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.security_ticker'
                              )
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="SecurityTicker"
                              size="sm"
                              type="text"
                              v-model="securityData.SecurityTicker"
                              :state="errors.length > 0 ? false : null"
                              @change="
                                securityTickerCheck(securityData.SecurityTicker)
                              "
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- security isin -->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.security_isin')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.security_isin'
                              )
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="SecurityTicker"
                              size="sm"
                              type="text"
                              v-model="securityData.SecurityIsin"
                              :state="errors.length > 0 ? false : null"
                              @change="
                                securityIsinCheck(securityData.SecurityIsin)
                              "
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- currency -->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.currency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('security_creation.security_form.currency')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="securityData.SecurityCcy"
                              id="SecurityCcy"
                              :clearable="false"
                              :options="optCurrencies"
                              :state="errors.length > 0 ? false : null"
                              :reduce="(val) => val.Currency"
                              label="Currency"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- country -->
                        <b-form-group
                          :label="$t('security_creation.security_form.country')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('security_creation.security_form.country')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="securityData.Country"
                              :label="
                                $t('security_creation.security_form.country')
                              "
                              id="Country"
                              :clearable="false"
                              :options="countryNames"
                              :taggable="false"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- default quantity -->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.default_shares')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.default_shares'
                              )
                            "
                            rules="min_value:1"
                          >
                            <b-form-input
                              id="DefaultShares"
                              size="sm"
                              type="number"
                              v-model="securityData.DefaultShares"
                              :state="errors.length > 0 ? false : null"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="SecurityModalLoading">
          <div
            v-if="securityTickerCheckLoading"
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_checking_message")
              }}
            </p>
          </div>

          <div
            v-else-if="securityIsinCheckLoading"
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_checking_message")
              }}
            </p>
          </div>

          <div
            v-else
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_creating_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="12"
            lg="12"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              size="sm"
              class="ml-1 modal-btn"
              @click="resetSecurityModal"
            >
              {{ $t("security_creation.buttons.cancel") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="ml-1 modal-btn"
              @click="createSecurity"
            >
              {{ $t("security_creation.buttons.create") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    {{/****END*** security create modal*/ }}

    {{ /*security amend modal*/ }}

    <b-modal
      v-model="showBondModal"
      :no-enforce-focus="true"
      id="bondUploadModal"
      title="Upload Payment Schedule"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      no-close-on-backdrop
      ok-title="OK"
      @hidden="resetShowbondModal"
      @ok="resetShowbondModal"
    >
      <b-overlay rounded="sm" no-fade>
        <b-container>
          <b-card
            :title="$t('security_creation.titles.bond_details')"
            class="compact_card_title"
          >
            <div>
              <b-form-file
                :disabled="securityData.SecurityTicker == null"
                accept=".xls, .xlsx, .xlsb"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @change="importExcelFile"
                ref="file-input"
              />
            </div>
          </b-card>
        </b-container>
      </b-overlay>
    </b-modal>

    <b-modal
      :no-enforce-focus="true"
      id="securityModal"
      v-model="securityAmendModal"
      :title="$t('security_creation.titles.security_amendment_form')"
      @hidden="resetSecurityModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      no-close-on-backdrop
      :ok-title="$t('security_creation.buttons.amend')"
      :cancel-title="$t('security_creation.buttons.cancel')"
      @ok="amendSecurity"
    >
      <b-overlay :show="SecurityModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="amendValidation">
            <b-form>
              <b-row class="mt-2" style="justify-content: center">
                <b-col cols="12" xl="6" lg="6" md="12" sm="12">
                  <b-card
                    :title="$t('security_creation.titles.security_details')"
                    class="compact_card_title"
                  >
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- SecurityType-->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.security_type')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.security_type'
                              )
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="securityData.SecurityType"
                              :label="
                                $t(
                                  'security_creation.security_form.security_isin'
                                )
                              "
                              id="securityType"
                              :state="errors.length > 0 ? false : null"
                              :clearable="false"
                              :options="optSecurityTypes"
                              :disabled="limited_change_input"
                              @change="
                                changeSecurityType(securityData.SecurityType)
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- security ticker-->
                        <b-form-group
                          :label="
                            $t(
                              'security_creation.security_form.security_ticker'
                            )
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.security_ticker'
                              )
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="SecurityTicker"
                              size="sm"
                              type="text"
                              v-model="securityData.SecurityTicker"
                              :state="errors.length > 0 ? false : null"
                              @change="
                                securityTickerCheck(securityData.SecurityTicker)
                              "
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- security isin -->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.security_isin')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.security_isin'
                              )
                            "
                            rules="required"
                          >
                            <b-form-input
                              id="SecurityTicker"
                              size="sm"
                              type="text"
                              v-model="securityData.SecurityIsin"
                              :state="errors.length > 0 ? false : null"
                              @change="
                                securityIsinCheck(securityData.SecurityIsin)
                              "
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- currency -->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.currency')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('security_creation.security_form.currency')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="securityData.SecurityCcy"
                              id="SecurityCcy"
                              :clearable="false"
                              :options="optCurrencies"
                              :state="errors.length > 0 ? false : null"
                              :reduce="(val) => val.Currency"
                              :disabled="limited_change_input"
                              label="Currency"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- country -->

                        <b-form-group
                          :label="$t('security_creation.security_form.country')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('security_creation.security_form.country')
                            "
                            rules="required"
                          >
                            <v-select
                              v-model="securityData.Country"
                              :label="
                                $t('security_creation.security_form.country')
                              "
                              id="Country"
                              :clearable="false"
                              :options="countryNames"
                              :taggable="false"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" xl="6" lg="6" md="6" sm="6">
                        <!-- default quantity -->
                        <b-form-group
                          :label="
                            $t('security_creation.security_form.default_shares')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t(
                                'security_creation.security_form.default_shares'
                              )
                            "
                            rules="min_value:1"
                          >
                            <b-form-input
                              id="DefaultShares"
                              size="sm"
                              type="number"
                              v-model="securityData.DefaultShares"
                              :state="errors.length > 0 ? false : null"
                            ></b-form-input>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="SecurityModalLoading">
          <div
            v-if="securityTickerCheckLoading"
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_checking_message")
              }}
            </p>
          </div>

          <div
            v-else-if="securityIsinCheckLoading"
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_checking_message")
              }}
            </p>
          </div>

          <div
            v-else
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_amending_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** amend modal*/ }}
    </b-modal>

    {{ /*security verify modal*/ }}
    <b-modal
      id="securityModal"
      v-model="securityVerifyModal"
      :title="$t('security_creation.titles.security_description_form')"
      @hidden="resetSecurityModal"
      size="lg"
      no-close-on-backdrop
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      :ok-title="$t('security_creation.buttons.verify')"
      :cancel-title="$t('security_creation.buttons.cancel')"
      @ok="verifySecurity"
    >
      <b-overlay :show="SecurityModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="createValidation">
            <b-form>
              <b-row class="mt-2" style="justify-content: center">
                <b-col cols="12" xl="6" lg="6" md="12" sm="12">
                  <b-card
                    :title="$t('security_creation.titles.security_details')"
                    class="compact_card_title"
                  >
                    <!-- SecurityType-->
                    <b-form-group
                      :label="
                        $t('security_creation.security_form.security_type')
                      "
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('security_creation.security_form.security_type')
                        "
                      >
                        <v-select
                          v-model="securityData.SecurityType"
                          :label="
                            $t('security_creation.security_form.security_isin')
                          "
                          id="securityType"
                          :state="errors.length > 0 ? false : null"
                          :clearable="false"
                          :options="optSecurityTypes"
                          :disabled="true"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- security ticker-->
                    <b-form-group
                      :label="
                        $t('security_creation.security_form.security_ticker')
                      "
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('security_creation.security_form.security_ticker')
                        "
                      >
                        <b-form-input
                          id="SecurityTicker"
                          size="sm"
                          type="text"
                          v-model="securityData.SecurityTicker"
                          :state="errors.length > 0 ? false : null"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- security isin -->
                    <b-form-group
                      :label="
                        $t('security_creation.security_form.security_isin')
                      "
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('security_creation.security_form.security_isin')
                        "
                      >
                        <b-form-input
                          id="SecurityTicker"
                          size="sm"
                          type="text"
                          v-model="securityData.SecurityIsin"
                          :state="errors.length > 0 ? false : null"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- currency -->
                    <b-form-group
                      :label="$t('security_creation.security_form.currency')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('security_creation.security_form.currency')"
                        rules="required"
                      >
                        <v-select
                          v-model="securityData.SecurityCcy"
                          id="SecurityCcy"
                          :clearable="false"
                          :options="optCurrencies"
                          :state="errors.length > 0 ? false : null"
                          :reduce="(val) => val.Currency"
                          label="Currency"
                          :disabled="true"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      :label="$t('security_creation.security_form.country')"
                    >
                      <v-select
                        v-model="securityData.Country"
                        :label="$t('security_creation.security_form.country')"
                        id="Country"
                        :clearable="false"
                        :options="countryNames"
                        :taggable="false"
                        :disabled="true"
                      />
                    </b-form-group>

                    <!-- default quantity -->
                    <b-form-group
                      :label="
                        $t('security_creation.security_form.default_shares')
                      "
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="
                          $t('security_creation.security_form.default_shares')
                        "
                      >
                        <b-form-input
                          id="DefaultShares"
                          size="sm"
                          type="number"
                          v-model="securityData.DefaultShares"
                          :state="errors.length > 0 ? false : null"
                          :disabled="true"
                        ></b-form-input>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-card>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>

        <template #overlay v-if="SecurityModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="TargetIcon" />{{
                $t("security_creation.messages.security_verifying_message")
              }}
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** verify modal*/ }}
    </b-modal>

    <b-modal
      id="scheduleModal"
      v-model="viewScheduleModal"
      :title="$t('security_creation.titles.view_amortization')"
      size="sm"
      :ok-title="$t('security_creation.buttons.ok')"
      :cancel-title="$t('security_creation.buttons.cancel')"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
    >
      <b-container>
        <validation-observer ref="createValidation">
          <b-form>
            <b-row class="mt-0">
              <b-col
                cols="12"
                xl="12"
                md="12"
                sm="12"
                class="bodyRow mt-1"
                style="padding: 0.5rem 0;"
              >
                <b-table
                  striped
                  hover
                  ref="refJournalEntriesTable"
                  class="position-relative"
                  :items="bondSchedules"
                  selectable
                  select-mode="single"
                  responsive
                  head-variant="light"
                  :fields="scheduleColumns"
                  :tbody-tr-class="scheduleClass"
                  show-empty
                  :empty-text="$t('general_messages.no_matching_recors_found')"
                >
                  <template #cell(Period)="data">
                    <strong>{{ data.item.Period }}</strong>
                  </template>

                  <template #cell(Date)="data">
                    <strong>{{ data.item.Date }}</strong>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-container>

      <!-- <template #modal-footer>
        <div class="w-100">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="float-right"
            @click="tradeOkOperation"
          >
            {{$t('equity_bond.modals.view_trade_journal_modal_ok')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="md"
            class="float-right mr-1"
            @click="resetTradeModal"
          >
          {{ $t('equity_bond.modals.modal_close') }}
          </b-button>
        </div>
      </template> -->
      {{/****END*** view journal for trades modal*/}}
    </b-modal>

    <b-modal
      id="showPaymentsModal"
      v-model="showPaymentsModal"
      title="Bond Payments Schedule"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <div class="mt-2 mb-2">
            <label
              >Upload New Schedule (Previous data will be replaced. )</label
            >
            <b-form-file
              accept=".xls, .xlsx, .xlsb"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              @change="replaceExcelFile"
              ref="file-input"
            />
          </div>

          <div class="d-flex mt-2 mb-2 align-items-center justify-content-end">
            <label>Face Amt <strong> (1M)</strong></label>
          </div>

          <b-table
            striped
            hover
            ref="refShowPaymentsModal"
            class="position-relative"
            :items="bondPaymentsSchedule"
            selectable
            select-mode="single"
            responsive
            head-variant="light"
            :fields="['Ticker', 'Date', 'Interest', 'Principal', 'Total']"
            show-empty
            :empty-text="$t('general_messages.no_matching_recors_found')"
          >
            <template #cell(ID)="data">
              <strong>{{ data.item.ID }}</strong>
            </template>

            <template #cell(SecurityIsin)="data">
              <strong>{{ data.item.SecurityIsin }}</strong>
            </template>

            <template #cell(Interest)="data">
              <strong>{{
                data.item.Interest ? formatPrice(data.item.Interest) : 0.0
              }}</strong>
            </template>

            <template #cell(Principal)="data">
              <strong>{{
                data.item.Principal ? formatPrice(data.item.Principal) : 0.0
              }}</strong>
            </template>

            <template #cell(Total)="data">
              <strong>{{
                data.item.Total ? formatPrice(data.item.Total) : 0.0
              }}</strong>
            </template>
            <template #cell(info)="data">
              <b-badge
                v-if="data.item.info == 'Pass'"
                pill
                :variant="`light-success`"
                class="text-capitalize"
                style="background-color:rgba(255, 255, 255, 0.5)"
              >
                {{ data.item.info }}
              </b-badge>

              <b-badge
                v-else
                pill
                :variant="`light-danger`"
                class="text-capitalize"
                style="background-color:rgba(255, 255, 255, 0.5)"
              >
                {{ data.item.info }}
              </b-badge>
            </template>

            <template #cell(reason)="data">
              <strong>{{ data.item.reason }}</strong>
            </template>
          </b-table>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import securityStoreModule from "./securityStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { type } from "os";
import VueContext from "vue-context";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    BTooltip,
    VueContext,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const SECURITY_APP_STORE_MODULE_NAME = "security";
    // Register module
    if (!store.hasModule(SECURITY_APP_STORE_MODULE_NAME))
      store.registerModule(SECURITY_APP_STORE_MODULE_NAME, securityStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SECURITY_APP_STORE_MODULE_NAME))
        store.unregisterModule(SECURITY_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      countryNames: null,
      allSecurityData: [],
      securityCreateModal: false,
      accountViewModal: false,
      securityAmendModal: false,
      securityVerifyModal: false,
      createCodeModal: false,
      allTeams: [],
      filterCreateModal: null,
      filterCreateModalOn: ["TeamName"],
      updatePermloading: false,
      SecurityModalLoading: false,
      securityTickerCheckLoading: false,
      securityIsinCheckLoading: false,
      limited_change_input: false,
      amendedReasonText: null,
      preAccount: null,
      securityData: {
        ID: null,
        SecurityType: null,
        SecurityTicker: null,
        SecurityIsin: null,
        SecurityCcy: null,
        MaturityDate: null,
        FirstInterestDate: null,
        IssueDate: null,
        CreatedBy: null,
        Basis: null,
        Frequency: null,
        Rate: null,
        CreationDate: null,
        CreationTime: null,
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
        Country: "United Kingdom",
        DefaultShares: 1,
      },
      optSecurityTypes: ["Equity", "Bond", "Currency"],
      securityTypes: [
        { title: "all", value: "" },
        { title: "Equity", value: "Equity" },
        { title: "Bond", value: "Bond" },
        { title: "Currency", value: "Currency" },
      ],
      optStatuses: [
        { title: "all", value: "" },
        { title: "Verified", value: false },
        { title: "Unverified", value: true },
      ],
      allCurrencies: [],
      optBasis: [
        "Actual/Actual",
        "US NASD(30/360)",
        "Actual/360",
        "Actual/365",
        "European 30/360",
      ],
      optFrequency: ["Annual", "Semi Annual", "Quarterly", "Monthly"],
      optCurrencies: [],
      filterOn: ["SecurityTicker"],
      sortByDesc: true,
      sortBy: "id",
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "table-padding sticky-column",
          thClass: "sticky-column text-center",
          tdClass: "text-center ID_background",
          thStyle: "width: 50px",
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("security_creation.table.status"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "SecurityType",
          label: this.$t("security_creation.table.security_type"),
          sortable: true,
          class: "text-center table-padding",
        },
        {
          key: "SecurityTicker",
          label: this.$t("security_creation.table.security_ticker"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "SecurityIsin",
          label: this.$t("security_creation.table.security_isin"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "SecurityCcy",
          label: this.$t("security_creation.table.security_ccy"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Country",
          label: this.$t("security_creation.table.country"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "CreatedBy",
          label: this.$t("security_creation.table.created_by"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "CreationDate",
          label: this.$t("security_creation.table.creation_date"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "CreationTime",
          label: this.$t("security_creation.table.creation_time"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "VerifiedBy",
          label: this.$t("security_creation.table.verified_by"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "VerificationDate",
          label: this.$t("security_creation.table.verification_date"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "VerificationTime",
          label: this.$t("security_creation.table.verification_time"),
          sortable: false,
          class: "text-center table-padding",
        },

        //   {

        //   key: "TradeActions",
        //   label: "Actions",
        //   class: "text-center",
        //   thStyle:"background-color:#ebe9f1;color:black;border: 1px solid #ebe9f1;"
        // },
      ],
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.id"
          ),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "SecurityIsin",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.security_isin"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 20%",
        },
        {
          key: "info",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.info"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 20%",
        },
        {
          key: "reason",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.reason"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 40%",
        },
      ],

      viewScheduleModal: false,
      bondSchedules: [],
      scheduleColumns: [
        {
          key: "Period",
          label: "Period",
          class: "text-center w-0 padding_08",
        },
        {
          key: "Date",
          label: "Date",
          sortable: false,
          class: "text-center padding_08",
        },
      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [15, 25, 50, 100],
      filter: null,

      loading: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        date: {
          date: true,
          datePattern: ["d", "m", "Y"],
          delimiter: "-",
        },
      },
      filters: {
        SecurityType: "",
        SecurityTicker: "",
        SecurityCcy: "",
      },
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      seeVerifyButton: false,
      defaultRate: null,
      rates: [],
      selectedRate: null,
      excelJson: null,
      showBondModal: false,
      showPaymentsModal: false,
      bondPaymentsSchedule: [],
      selectedItemTicker: null,
    };
  },
  computed: {
    ...mapGetters(["authUser"]),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    filteredTrades() {
      const filtered = this.allSecurityData.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0 ? filtered : filtered;
      // : [
      //     {
      //       SecurityType: "",
      //       SecurityTicker: "",
      //       SecurityCcy: "",
      //     },
      //   ];
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.getAllEntries();
    this.getCurrencies();
    this.getCountries();
  },
  methods: {
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    viewPaymentSchedule(item) {
      this.selectedItemTicker = item.SecurityTicker;

      store
        .dispatch("security/getPaymentSchedule", {
          Ticker: item.SecurityTicker,
        })
        .then((res) => {
          this.bondPaymentsSchedule = res.data.data;
          this.showPaymentsModal = true;
        })
        .catch();
    },

    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },

    showTradeControlsPopover(targetTrade, trade) {
      if (trade) {
        // set default
        this.seeVerifyButton = false;

        // set suitable flow buttons

        // see verify button
        if (
          trade.VerifiedBy == null &&
          trade.VerificationTime == null &&
          trade.VerificationDate == null
        ) {
          this.seeVerifyButton = true;
        }
      }

      this.$root.$emit("bv::show::popover", targetTrade);
    },

    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
      }

      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];

        // this.tableColumns[1].class = this.showMultiplecheckboks
        //   ? "text-center"
        //   : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));
      }
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          id: trade.id,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];

      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          VerifiedBy: null,
          VerificationTime: null,
          VerificationDate: null,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          VerifiedBy: null,
          VerificationTime: null,
          VerificationDate: null,
        });
      }

      if (trades.length > 0 && status) {
        if (status == "copyTrades") {
          //this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
          this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },

    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel" ? "textarea" : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;
          store
            .dispatch("security/tradeMultipleChangeSecurityStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
            })
            .then((res) => {
              self.loading = false;
              self.multiSelectProcessModalTableData = [];

              if (res.data.successProcesses.length > 0) {
                self.multiSelectProcessModalTableData.push(
                  ...res.data.successProcesses
                );
              }

              if (res.data.failedProcesses.length > 0) {
                self.multiSelectProcessModalTableData.push(
                  ...res.data.failedProcesses
                );
              }
              self.showMultiSelectProcessModal = true;

              if (res.data.failedProcesses.length > 0) {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage();
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();
                _.mapValues(
                  this.allEquityBondsData,
                  (v) => (v.selectedTrade = false)
                );
                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    // tradeMultipleSelectCopyOpr(tradesCount, trades) {
    //   const self = this;
    //   this.$swal({
    //     title: self.$t("multiCheckBoxTitles.change_copy_title"),
    //     text:
    //       tradesCount +
    //       self.$t("multiCheckBoxTitles.trades_copy_message"),
    //     //icon: "warning",
    //     showCancelButton: true,
    //     showLoaderOnConfirm: true,
    //     inputAttributes: {
    //       style: "margin-top:5px!important",
    //     },
    //     confirmButtonColor: "#3085d6",
    //     cancelButtonColor: "#d33",
    //     confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
    //     cancelButtonText: self.$t("general_buttons.cancel"),
    //     customClass: {
    //       confirmButton: "btn btn-primary mr-1",
    //       cancelButton: "btn btn-danger",
    //       denyButton: "btn btn-warning",
    //       text: "mb-1",
    //     },
    //     showClass: {
    //       popup: "animate__animated animate__fadeInDown",
    //     },
    //     hideClass: {
    //       popup: "animate__animated animate__fadeOutUp",
    //     },
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       self.loading = true;

    //       // set trade data
    //         if (trades.length > 0) {
    //           var setTradesData = [];
    //           trades.forEach((trade) => {
    //             setTradesData.push(self.setTradeData(trade));
    //           });
    //         }
    //         trades = setTradesData;
    //       store
    //         .dispatch("security/tradeMultipleSelectTransactionCopyOpr", {
    //           auth: self.user,
    //           trades: trades,
    //         })
    //         .then((res) => {
    //           self.loading = false;
    //           self.selectedMultipleTrades = [];

    //           if(res.data.failedCopyOpr.length > 0){
    //             this.multiSelectCopyWarningMessage()
    //             this.okMultiSelectProcessModal()
    //           } else if(res.data.info == 'not_found'){
    //             this.wrongToastMessage(res.data.message)
    //           } else {
    //             self.multiSelectAllTradesCopySuccessMessage();
    //             this.okMultiSelectProcessModal()
    //           }
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //         });
    //     }
    //   });
    // },

    okMultiSelectProcessModal() {
      this.showMultiSelectProcessModal = false;
      this.multiSelectProcessModalTableData = [];
      _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));

      this.getAllEntries();
    },

    getCurrencies() {
      this.$checkAccessRight("SecurityCreation", "Search").then((res) => {
        if (res.data.valid) {
          axiosIns
            .post(`getCurrencies`, this.user)
            .then((res) => {
              this.allCurrencies = [];
              if (res.data.length > 0) {
                this.allCurrencies.push({ title: "all", value: "" });
                res.data.forEach((c) => {
                  this.allCurrencies.push({
                    title: c.Currency,
                    value: c.Currency,
                  });
                });
              }
            })
            .catch((error) => reject(error));
        } else {
          this.loading = false;
        }
      });
    },
    //ALL DATAS
    getAllEntries() {
      this.loading = true;

      this.$checkAccessRight("SecurityCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("security/getAllSecurities", this.user)
            .then((res) => {
              this.allSecurityData = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //VERIFIED DATAS
    verifiedEntries() {
      this.loading = true;

      this.$checkAccessRight("SecurityCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("security/getAllVerifiedSecurities", this.user)
            .then((res) => {
              this.allSecurityData = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //UNVERIFIED DATAS
    unverifiedEntries() {
      this.loading = true;
      this.$checkAccessRight("SecurityCreation", "Search").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("security/getAllUnverifiedSecurities", this.user)
            .then((res) => {
              this.allSecurityData = res.data;
              this.totalRows = res.data.length;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //AMEND BUTTON
    amendEntry(item) {
      this.$checkAccessRight("SecurityCreation", "Amend").then((res) => {
        if (res.data.valid) {
          if (item.id != null && item.id != 0) {
            this.SecurityModalLoading = true;
            this.loading = true;
            store
              .dispatch("security/checkUserCanAmendSecurityDescription", {
                auth: this.user,
                securityID: item.id,
                createdBy: item.CreatedBy,
              })
              .then((res) => {
                if (res.data.info == "not_auth_amend") {
                  this.wrongToastMessage(
                    this.$t("security_creation.messages.can_not_amended")
                  );
                  this.SecurityModalLoading = false;
                  this.loading = false;
                } else if (res.data.info == "limited_change_input") {
                  this.infoToastMessage(
                    this.$t(
                      "security_creation.messages.limited_change_input",
                      8000
                    )
                  );

                  if (res.data.security.SecurityType == "Bond") {
                    this.securityData = {
                      ID: res.data.security.id,
                      SecurityType: res.data.security.SecurityType,
                      Country: res.data.security.Country,
                      DefaultShares: res.data.security.DefaultShares,
                      SecurityTicker: res.data.security.SecurityTicker,
                      SecurityIsin: res.data.security.SecurityIsin,
                      SecurityCcy: res.data.security.SecurityCcy,
                      CreatedBy: res.data.security.CreatedBy,
                      CreationDate: this.dateFormat(
                        res.data.security.CreationDate
                      ),
                      CreationTime:
                        res.data.security.CreationTime != null
                          ? res.data.security.CreationTime.split(" ")[1]
                          : null,
                      VerificationDate: this.dateFormat(
                        res.data.security.VerificationDate
                      ),
                      VerificationTime:
                        res.data.security.VerificationTime != null
                          ? res.data.security.VerificationTime.split(" ")[1]
                          : null,
                      VerifiedBy: res.data.security.VerifiedBy,
                      MaturityDate: res.data.security.MaturityDate
                        ? this.DMYdateFormat(res.data.security.MaturityDate)
                        : null,
                      FirstInterestDate: res.data.security.FirstInterestDate
                        ? this.DMYdateFormat(
                            res.data.security.FirstInterestDate
                          )
                        : null,
                      IssueDate: res.data.security.IssueDate
                        ? this.DMYdateFormat(res.data.security.IssueDate)
                        : null,
                      Basis: res.data.security.Basis,
                      Frequency: res.data.security.Frequency,
                      Rate: res.data.security.Rate,
                    };
                  } else {
                    this.securityData = {
                      ID: res.data.security.id,
                      SecurityType: res.data.security.SecurityType,
                      Country: res.data.security.Country,
                      DefaultShares: res.data.security.DefaultShares,
                      SecurityTicker: res.data.security.SecurityTicker,
                      SecurityIsin: res.data.security.SecurityIsin,
                      SecurityCcy: res.data.security.SecurityCcy,
                      CreatedBy: res.data.security.CreatedBy,
                      CreationDate: this.dateFormat(
                        res.data.security.CreationDate
                      ),
                      CreationTime:
                        res.data.security.CreationTime != null
                          ? res.data.security.CreationTime.split(" ")[1]
                          : null,
                      VerificationDate: this.dateFormat(
                        res.data.security.VerificationDate
                      ),
                      VerificationTime:
                        res.data.security.VerificationTime != null
                          ? res.data.security.VerificationTime.split(" ")[1]
                          : null,
                      VerifiedBy: res.data.security.VerifiedBy,
                    };

                    if (this.securityData.SecurityTicker != null) {
                      if (
                        this.securityData.SecurityTicker.search("Govt") > -1
                      ) {
                        this.securityData.SecurityTicker = this.securityData.SecurityTicker.split(
                          "Govt"
                        )[0];
                      } else if (
                        this.securityData.SecurityTicker.search("Corp") > -1
                      ) {
                        this.securityData.SecurityTicker = this.securityData.SecurityTicker.split(
                          "Corp"
                        )[0];
                      }
                    }
                  }

                  this.preAccount = JSON.parse(
                    JSON.stringify(this.securityData)
                  );
                  this.loading = false;
                  this.SecurityModalLoading = false;
                  this.securityAmendModal = true;
                  this.limited_change_input = true;
                } else if (res.data.info == "Pass") {
                  if (res.data.security.SecurityType == "Bond") {
                    this.securityData = {
                      ID: res.data.security.id,
                      SecurityType: res.data.security.SecurityType,
                      Country: res.data.security.Country,
                      DefaultShares: res.data.security.DefaultShares,
                      SecurityTicker: res.data.security.SecurityTicker,
                      SecurityIsin: res.data.security.SecurityIsin,
                      SecurityCcy: res.data.security.SecurityCcy,
                      CreatedBy: res.data.security.CreatedBy,
                      CreationDate: this.dateFormat(
                        res.data.security.CreationDate
                      ),
                      CreationTime:
                        res.data.security.CreationTime != null
                          ? res.data.security.CreationTime.split(" ")[1]
                          : null,
                      VerificationDate: this.dateFormat(
                        res.data.security.VerificationDate
                      ),
                      VerificationTime:
                        res.data.security.VerificationTime != null
                          ? res.data.security.VerificationTime.split(" ")[1]
                          : null,
                      VerifiedBy: res.data.security.VerifiedBy,
                      MaturityDate: res.data.security.MaturityDate
                        ? this.DMYdateFormat(res.data.security.MaturityDate)
                        : null,
                      FirstInterestDate: res.data.security.FirstInterestDate
                        ? this.DMYdateFormat(
                            res.data.security.FirstInterestDate
                          )
                        : null,
                      IssueDate: res.data.security.IssueDate
                        ? this.DMYdateFormat(res.data.security.IssueDate)
                        : null,
                      Basis: res.data.security.Basis,
                      Frequency: res.data.security.Frequency,
                      Rate: res.data.security.Rate,
                    };
                  } else {
                    this.securityData = {
                      ID: res.data.security.id,
                      SecurityType: res.data.security.SecurityType,
                      Country: res.data.security.Country,
                      DefaultShares: res.data.security.DefaultShares,
                      SecurityTicker: res.data.security.SecurityTicker,
                      SecurityIsin: res.data.security.SecurityIsin,
                      SecurityCcy: res.data.security.SecurityCcy,
                      CreatedBy: res.data.security.CreatedBy,
                      CreationDate: this.dateFormat(
                        res.data.security.CreationDate
                      ),
                      CreationTime:
                        res.data.security.CreationTime != null
                          ? res.data.security.CreationTime.split(" ")[1]
                          : null,
                      VerificationDate: this.dateFormat(
                        res.data.security.VerificationDate
                      ),
                      VerificationTime:
                        res.data.security.VerificationTime != null
                          ? res.data.security.VerificationTime.split(" ")[1]
                          : null,
                      VerifiedBy: res.data.security.VerifiedBy,
                    };
                    if (this.securityData.SecurityTicker != null) {
                      if (
                        this.securityData.SecurityTicker.search("Govt") > -1
                      ) {
                        this.securityData.SecurityTicker = this.securityData.SecurityTicker.split(
                          "Govt"
                        )[0];
                      } else if (
                        this.securityData.SecurityTicker.search("Corp") > -1
                      ) {
                        this.securityData.SecurityTicker = this.securityData.SecurityTicker.split(
                          "Corp"
                        )[0];
                      }
                    }
                  }

                  this.optCurrencies = res.data.currencies;
                  this.preAccount = JSON.parse(
                    JSON.stringify(this.securityData)
                  );
                  this.loading = false;
                  this.SecurityModalLoading = false;
                  this.securityAmendModal = true;
                } else {
                  this.securityNotFoundMessage();
                  this.SecurityModalLoading = false;
                  this.loading = false;
                }
              })
              .catch((error) => {
                this.SecurityModalLoading = false;
                this.loading = false;
                console.log(error);
              });
          } else {
            this.securityDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    getSecuritySchedule(item) {
      store
        .dispatch("security/getSecuritySchedule", {
          auth: this.user,
          securityID: item.id,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.bondSchedules = res.data.schedule;
            this.viewScheduleModal = true;
            this.checkBondCouponPayment();
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkBondCouponPayment() {
      store
        .dispatch("security/checkBondCouponPayment")
        .then((res) => {})
        .catch((error) => {
          this.errorMessage(error);
        });
    },

    //AMEND POPUP OK
    amendSecurity(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (!_.isEqual(this.preAccount, this.securityData)) {
        this.$checkAccessRight("SecurityCreation", "Amend").then((res) => {
          if (res.data.valid) {
            this.$refs.amendValidation.validate().then((success) => {
              if (success) {
                this.SecurityModalLoading = true;

                let checkSecurityIsin = 0;
                let checkSecurityTicker = 0;

                if (
                  this.preAccount.SecurityIsin != this.securityData.SecurityIsin
                ) {
                  checkSecurityIsin = 1;
                }

                if (
                  this.preAccount.SecurityTicker !=
                  this.securityData.SecurityTicker
                ) {
                  checkSecurityTicker = 1;
                }

                let creationDate = this.YMDdateFormat(
                  this.securityData.CreationDate
                );
                let verificationDate = this.YMDdateFormat(
                  this.securityData.VerificationDate
                );

                this.securityData.CreationDate = creationDate;
                this.securityData.VerificationDate = verificationDate;

                this.securityData.SecurityTicker = this.securityData.SecurityTicker;

                store
                  .dispatch("security/updateAmendSecurity", {
                    auth: this.user,
                    securityData: this.securityData,
                    FirstInterestDate: this.YMDdateFormat(
                      this.securityData.FirstInterestDate
                    ),
                    IssueDate: this.YMDdateFormat(this.securityData.IssueDate),
                    MaturityDate: this.YMDdateFormat(
                      this.securityData.MaturityDate
                    ),
                  })
                  .then((res) => {
                    if (res.data.info == "Pass") {
                      this.SecurityModalLoading = false;
                      this.securityAmendModal = false;
                      this.resetSecurityModal();
                      this.getAllEntries();
                    } else {
                      this.errorMessage(res.data.message);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                let notEnteredDatas = "";

                let checkRequiredDatas = _.map(
                  this.$refs.amendValidation.errors,
                  (value, key) => ({ key, value })
                );

                if (checkRequiredDatas.length > 0) {
                  let first = true;
                  checkRequiredDatas.forEach((r) => {
                    if (r.value.length > 0) {
                      if (first) {
                        notEnteredDatas += r.key;
                        first = false;
                      } else {
                        notEnteredDatas += "," + r.key;
                        first = false;
                      }
                    }
                  });
                  this.checkRequiredInputsMessage(notEnteredDatas, 20000);
                }
              }
            });
          } else {
            this.notAuthToastMessage();
          }
        });
      } else {
        this.noChangesMessage();
      }
    },

    //VERIFY BUTTON
    verifySelectedAccount(item) {
      this.$checkAccessRight("SecurityCreation", "Verify").then((res) => {
        if (res.data.valid) {
          if (item.id != 0) {
            this.loading = true;
            store
              .dispatch("security/checkVerifySecurity", {
                auth: this.user,
                securityID: item.id,
                createdBy: item.CreatedBy,
              })
              .then((res) => {
                if (res.data.info == "not_found") {
                  this.securityNotFoundMessage();
                }

                if (res.data.info == "old_record") {
                  this.oldSecurityMessage();
                }

                if (res.data.info == "verified_record") {
                  this.verifiedSecurityMessage();
                }

                if (res.data.info == "create_yours") {
                  this.warningMessage(
                    this.$t("security_creation.messages.created_by_you") +
                      item.CreatedBy +
                      this.$t("security_creation.messages.created_by_you2")
                  );
                }

                if (res.data.info == "not_verified") {
                  if (res.data.security.SecurityType == "Bond") {
                    this.securityData = {
                      ID: res.data.security.id,
                      SecurityType: res.data.security.SecurityType,
                      Country: res.data.security.Country,
                      DefaultShares: res.data.security.DefaultShares,
                      SecurityTicker:
                        res.data.security.SecurityTicker != null
                          ? res.data.security.SecurityTicker
                          : null,
                      SecurityIsin: res.data.security.SecurityIsin,
                      SecurityCcy: res.data.security.SecurityCcy,
                      CreatedBy: res.data.security.CreatedBy,
                      CreationDate: this.dateFormat(
                        res.data.security.CreationDate
                      ),
                      CreationTime:
                        res.data.security.CreationTime != null
                          ? res.data.security.CreationTime.split(" ")[1]
                          : null,
                      VerificationDate: this.dateFormat(
                        res.data.security.VerificationDate
                      ),
                      VerificationTime:
                        res.data.security.VerificationTime != null
                          ? res.data.security.VerificationTime.split(" ")[1]
                          : null,
                      VerifiedBy: res.data.security.VerifiedBy,
                      MaturityDate: res.data.security.MaturityDate
                        ? this.DMYdateFormat(res.data.security.MaturityDate)
                        : null,
                      FirstInterestDate: res.data.security.FirstInterestDate
                        ? this.DMYdateFormat(
                            res.data.security.FirstInterestDate
                          )
                        : null,
                      IssueDate: res.data.security.IssueDate
                        ? this.DMYdateFormat(res.data.security.IssueDate)
                        : null,

                      Basis: res.data.security.Basis,
                      Frequency: res.data.security.Frequency,
                      Rate: res.data.security.Rate,
                    };
                  } else {
                    this.securityData = {
                      ID: res.data.security.id,
                      SecurityType: res.data.security.SecurityType,
                      Country: res.data.security.Country,
                      DefaultShares: res.data.security.DefaultShares,

                      SecurityTicker:
                        res.data.security.SecurityTicker != null
                          ? res.data.security.SecurityTicker
                          : null,
                      SecurityIsin: res.data.security.SecurityIsin,
                      SecurityCcy: res.data.security.SecurityCcy,
                      CreatedBy: res.data.security.CreatedBy,
                      CreationDate: this.dateFormat(
                        res.data.security.CreationDate
                      ),
                      CreationTime:
                        res.data.security.CreationTime != null
                          ? res.data.security.CreationTime.split(" ")[1]
                          : null,
                      VerificationDate: this.dateFormat(
                        res.data.security.VerificationDate
                      ),
                      VerificationTime:
                        res.data.security.VerificationTime != null
                          ? res.data.security.VerificationTime.split(" ")[1]
                          : null,
                      VerifiedBy: res.data.security.VerifiedBy,
                    };
                  }

                  this.securityVerifyModal = true;
                }
                this.loading = false;
              })
              .catch((error) => {
                this.SecurityModalLoading = false;
                this.loading = false;
                console.log(error);
              });
          } else {
            this.securityDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //VERIFY POPUP OK
    verifySecurity(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("SecurityCreation", "Verify").then((res) => {
        if (res.data.valid) {
          this.SecurityModalLoading = true;

          //this.securityData.VerificationDate = moment().format("YYYY-MM-DD");
          //this.securityData.VerificationTime = moment().format("HH:mm:ss");
          //this.securityData.VerifiedBy = this.user.name;

          // console.log(this.securityData);
          store
            .dispatch("security/verifySecurity", {
              auth: this.user,
              securityID: this.securityData.ID,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                this.successVerifiedMessage();

                this.SecurityModalLoading = false;
                this.securityVerifyModal = false;
                this.getAllEntries();
              } else {
                this.SecurityModalLoading = false;
                this.errorMessage(res.data.message);
              }
            })
            .catch((error) => {
              this.SecurityModalLoading = false;
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************CREATE START
    createSecurityModal() {
      this.$checkAccessRight("SecurityCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          axiosIns
            .post(`getCurrencies`, this.user)
            .then((res) => {
              this.optCurrencies = res.data;

              let ccy = res.data.find((c) => c.Currency === "USD");
              if (ccy) {
                this.securityData.SecurityCcy = ccy.Currency;
              }
              this.securityData.SecurityType = this.optSecurityTypes[1];
              this.securityData.Basis = this.optBasis[0];
              this.securityCreateModal = true;
              this.loading = false;
            })
            .catch((error) => reject(error));
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    createSecurity(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("SecurityCreation", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.createValidation.validate().then((success) => {
            if (success) {
              this.SecurityModalLoading = true;
              this.securityData.CreationDate = moment().format("YYYY-MM-DD");
              this.securityData.CreationTime = moment().format("HH:mm:ss");
              this.securityData.CreatedBy =
                this.user.name + " " + this.user.surname;

              this.securityData.SecurityTicker = this.securityData.SecurityTicker;

              store
                .dispatch("security/insertIntoSecurity", {
                  auth: this.user,
                  securityData: this.securityData,
                  FirstInterestDate: this.YMDdateFormat(
                    this.securityData.FirstInterestDate
                  ),
                  IssueDate: this.YMDdateFormat(this.securityData.IssueDate),
                  MaturityDate: this.YMDdateFormat(
                    this.securityData.MaturityDate
                  ),
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    if (this.securityData.SecurityType == "Bond") {
                      this.showBondModal = true;
                    } else {
                      this.securityCreateMessage();
                      this.SecurityModalLoading = false;
                      this.securityCreateModal = false;
                      this.resetSecurityModal();
                      this.getAllEntries();
                    }
                  } else {
                    this.errorMessage(res.data.message);
                  }
                })
                .catch((error) => {
                  this.SecurityModalLoading = false;
                  //   this.securityCreateModal = false;
                  this.getAllEntries();

                  console.log(error);
                });
            } else {
              let notEnteredDatas = "";

              let checkRequiredDatas = _.map(
                this.$refs.createValidation.errors,
                (value, key) => ({ key, value })
              );

              if (checkRequiredDatas.length > 0) {
                let first = true;
                checkRequiredDatas.forEach((r) => {
                  if (r.value.length > 0) {
                    if (first) {
                      notEnteredDatas += r.key;
                      first = false;
                    } else {
                      notEnteredDatas += "," + r.key;
                      first = false;
                    }
                  }
                });
                this.checkRequiredInputsMessage(notEnteredDatas, 20000);
              }
            }
          });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    //****************CREATE END

    changeSecurityType(type) {
      if (type == "Equity") {
        this.securityData.Basis = null;
      }
    },

    securityTickerCheck(ticker) {
      if (
        this.securityData.SecurityTicker != null &&
        this.securityData.SecurityTicker != 0 &&
        this.securityData.SecurityTicker != ""
      ) {
        this.SecurityModalLoading = true;
        this.securityTickerCheckLoading = true;
        if (this.securityData.ID != null && this.securityData.ID != 0) {
          store
            .dispatch("security/securityTickerExistWithID", {
              auth: this.user,
              id: this.securityData.ID,
              securityTicker: this.securityData.SecurityTicker,
            })
            .then((res) => {
              if (res.data.exist == 1) {
                this.securityTickerExistMessage(
                  this.$t("security_creation.messages.security_ticker") +
                    this.securityData.SecurityTicker +
                    this.$t("security_creation.messages.security_ticker_exist")
                );
                this.securityData.SecurityTicker = null;
                this.securityTickerCheckLoading = false;
                this.SecurityModalLoading = false;
              } else {
                this.securityTickerCheckLoading = false;
                this.SecurityModalLoading = false;
              }
            })
            .catch((error) => {
              this.securityTickerCheckLoading = false;
              this.SecurityModalLoading = false;
              console.log(error);
            });
        } else {
          store
            .dispatch("security/securityTickerExist", {
              auth: this.user,
              securityTicker: this.securityData.SecurityTicker,
            })
            .then((res) => {
              if (res.data.exist == 1) {
                this.securityTickerExistMessage(
                  this.$t("security_creation.messages.security_ticker") +
                    this.securityData.SecurityTicker +
                    this.$t("security_creation.messages.security_ticker_exist")
                );
                this.securityData.SecurityTicker = null;
                this.securityTickerCheckLoading = false;
                this.SecurityModalLoading = false;
              } else {
                this.securityTickerCheckLoading = false;
                this.SecurityModalLoading = false;
              }
            })
            .catch((error) => {
              this.securityTickerCheckLoading = false;
              this.SecurityModalLoading = false;
              console.log(error);
            });
        }
      }
    },

    securityIsinCheck(isin) {
      if (
        this.securityData.SecurityIsin != null &&
        this.securityData.SecurityIsin != 0 &&
        this.securityData.SecurityIsin != ""
      ) {
        this.SecurityModalLoading = true;
        this.securityTickerCheckLoading = false;
        this.securityIsinCheckLoading = true;

        if (this.securityData.ID != null && this.securityData.ID != 0) {
          store
            .dispatch("security/securityIsinExistWithID", {
              auth: this.user,
              id: this.securityData.ID,
              securityIsin: this.securityData.SecurityIsin,
            })
            .then((res) => {
              if (res.data.exist == 1) {
                this.securityIsinExistMessage(
                  this.$t("security_creation.messages.security_isin") +
                    this.securityData.SecurityIsin +
                    this.$t("security_creation.messages.security_isin_exist")
                );
                this.securityData.SecurityIsin = null;
                this.securityIsinCheckLoading = false;
                this.SecurityModalLoading = false;
              } else {
                this.securityIsinCheckLoading = false;
                this.SecurityModalLoading = false;
              }
            })
            .catch((error) => {
              this.securityIsinCheckLoading = false;
              this.SecurityModalLoading = false;
              console.log(error);
            });
        } else {
          store
            .dispatch("security/securityIsinExist", {
              auth: this.user,
              securityIsin: this.securityData.SecurityIsin,
            })
            .then((res) => {
              if (res.data.exist == 1) {
                this.securityIsinExistMessage(
                  this.$t("security_creation.messages.security_isin") +
                    this.securityData.SecurityIsin +
                    this.$t("security_creation.messages.security_isin_exist")
                );
                this.securityData.SecurityIsin = null;
                this.securityIsinCheckLoading = false;
                this.SecurityModalLoading = false;
              } else {
                this.securityIsinCheckLoading = false;
                this.SecurityModalLoading = false;
              }
            })
            .catch((error) => {
              this.securityIsinCheckLoading = false;
              this.SecurityModalLoading = false;
              console.log(error);
            });
        }
      }
    },

    resetSecurityModal() {
      this.securityData = {
        ID: null,
        SecurityType: null,
        SecurityTicker: null,
        SecurityIsin: null,
        SecurityCcy: null,
        MaturityDate: null,
        FirstInterestDate: null,
        IssueDate: null,
        CreatedBy: null,
        Basis: null,
        Frequency: null,
        Rate: null,
        CreationDate: null,
        CreationTime: null,
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
        Country: "United Kingdom",
        DefaultShares: 1,
      };

      this.securityData.SecurityType = this.optSecurityTypes[1];
      this.securityData.Basis = this.optBasis[0];

      this.securityCreateModal = false;
      this.securityAmendModal = false;
      this.createCodeModal = false;
      this.showBondModal = false;
      this.preAccount = null;
      this.amendedReasonText = null;
      this.optCurrencies = [];
      this.securityTickerCheckLoading = false;
      this.securityIsinCheckLoading = false;
      this.limited_change_input = false;
      var index = this.countryNames.indexOf("All");
      if (index !== -1) {
        this.countryNames.splice(index, 1);
      }
    },
    resetShowbondModal() {
      this.securityData = {
        ID: null,
        SecurityType: null,
        SecurityTicker: null,
        SecurityIsin: null,
        SecurityCcy: null,
        MaturityDate: null,
        FirstInterestDate: null,
        IssueDate: null,
        CreatedBy: null,
        Basis: null,
        Frequency: null,
        Rate: null,
        CreationDate: null,
        CreationTime: null,
        VerificationDate: null,
        VerificationTime: null,
        VerifiedBy: null,
        Country: "United Kingdom",
        DefaultShares: 1,
      };

      this.securityData.SecurityType = this.optSecurityTypes[1];
      this.securityData.Basis = this.optBasis[0];

      this.securityCreateModal = false;
      this.securityAmendModal = false;
      this.createCodeModal = false;
      this.showBondModal = false;
      this.preAccount = null;
      this.amendedReasonText = null;
      this.optCurrencies = [];
      this.securityTickerCheckLoading = false;
      this.securityIsinCheckLoading = false;
      this.limited_change_input = false;
      var index = this.countryNames.indexOf("All");
      if (index !== -1) {
        this.countryNames.splice(index, 1);
      }
      this.getAllEntries();
    },
    checkOrdersDate(type) {
      if (type == "Maturity Date") {
        if (
          this.securityData.MaturityDate &&
          this.securityData.FirstInterestDate &&
          this.YMDdateFormat(this.securityData.MaturityDate) >
            this.YMDdateFormat(this.securityData.FirstInterestDate)
        ) {
          this.securityData.MaturityDate = null;
          // this.$refs.maturityPicker.fp.clear();

          this.securityDateError(
            this.$t("security_creation.messages.maturity_interest")
          );
        } else if (
          this.securityData.MaturityDate &&
          this.securityData.IssueDate &&
          this.YMDdateFormat(this.securityData.MaturityDate) >
            this.YMDdateFormat(this.securityData.IssueDate)
        ) {
          this.securityData.MaturityDate = null;
          // this.$refs.maturityPicker.fp.clear();

          this.securityDateError(
            this.$t("security_creation.messages.maturity_issue")
          );
        }
      } else if (type == "First Interest Date") {
        if (
          this.securityData.MaturityDate &&
          this.securityData.FirstInterestDate &&
          this.YMDdateFormat(this.securityData.FirstInterestDate) <
            this.YMDdateFormat(this.securityData.MaturityDate)
        ) {
          this.securityData.FirstInterestDate = null;
          // this.$refs.interestPicker.fp.clear();

          this.securityDateError(
            this.$t("security_creation.messages.interest_maturity")
          );
        } else if (
          this.securityData.IssueDate &&
          this.securityData.FirstInterestDate &&
          this.YMDdateFormat(this.securityData.FirstInterestDate) >
            this.YMDdateFormat(this.securityData.IssueDate)
        ) {
          this.securityData.FirstInterestDate = null;
          // this.$refs.interestPicker.fp.clear();

          this.securityDateError(
            this.$t("security_creation.messages.interest_issue")
          );
        }
      } else if (type == "Form Issue Date") {
        if (
          this.securityData.MaturityDate &&
          this.securityData.IssueDate &&
          this.YMDdateFormat(this.securityData.IssueDate) <
            this.YMDdateFormat(this.securityData.MaturityDate)
        ) {
          this.securityData.IssueDate = null;
          // this.$refs.issuePicker.fp.clear();

          this.securityDateError(
            this.$t("security_creation.messages.issue_maturity")
          );
        } else if (
          this.securityData.FirstInterestDate &&
          this.securityData.IssueDate &&
          this.YMDdateFormat(this.securityData.IssueDate) <
            this.YMDdateFormat(this.securityData.FirstInterestDate)
        ) {
          this.securityData.IssueDate = null;
          // this.$refs.issuePicker.fp.clear();

          this.securityDateError(
            this.$t("security_creation.messages.issue_interest")
          );
        }
      }
    },

    getCountries() {
      this.loading = true;
      store
        .dispatch("security/getTaxRates")
        .then((res) => {
          this.countryNames = res.data.rates.map((e) => e.Residency);
        })
        .catch((error) => {
          this.databaseErrorMessage();
          console.log(error);
        });
    },

    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      const sampleColor = "sampleColor";

      if (!item || type !== "row") {
        return;
      }

      // if (item.AmendedBy != null) {
      //   return journalCreateRowColor;
      // }
      // if (item.VerifiedBy != null) {
      //   return verifiedRowColor;
      // }
    },

    scheduleClass(item) {
      if (item.Passed) {
        return "table-verified";
      }
    },
    securityDateError(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("security_creation.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    amendOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("security_creation.messages.amend_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifiedSecurityMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("security_creation.messages.entry_already_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("security_creation.messages.security_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successVerifiedMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("security_creation.messages.security_verify_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successInsertSecurityMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: message,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    wrongToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    infoToastMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Amend Note",
            text: message,
            icon: "BookmarkIcon",
            variant: "primary",
          },
        },
        {
          timeout,
        }
      );
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("security_creation.messages.security_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityTickerExistMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityIsinExistMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("security_creation.messages.security_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    oldSecurityMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("security_creation.messages.old_security_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("security_creation.messages.not_authorized"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },

    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(val, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
      }
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("security_creation.messages.required_fill_inputs") +
              ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("security_creation.messages.you_not_change_account"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(String(val), "HH:mm:ss")._i;
      } else {
        return "-";
      }
    },

    importExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(Payment Date|Interest|Principal|Total)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 4) {
            this.excelJson.forEach((item) => {
              if (!item["Payment Date"].toString().includes("/")) {
                item["Payment Date"] = self.excelDateToJSDate(
                  item["Payment Date"]
                );
              }
            });
            store
              .dispatch("security/importBondData", {
                Ticker: self.securityData.SecurityTicker,
                json: this.excelJson,
              })
              .then((res) => {
                self.$refs["file-input"].reset();

                self.$swal({
                  title: `${
                    res.data.success_added_data == 0 ? "warning" : "success"
                  }`,
                  //   text: `${res.data.success_added_data} data successfully added. But ${res.data.error_rows} rows didn't add. Please check your rows and load those rows with a new excel `,
                  html: `
                  <span style="${
                    res.data.success_added_data == 0 ? "display:none" : ""
                  }">${
                    res.data.success_added_data
                  } data successfully added.</span>
                  <br>
                  <span style="${
                    res.data.error_rows.length > 0 ? "" : "display:none"
                  }">
                  <span style="${
                    res.data.success_added_data == 0 ? "display:none" : ""
                  }">But  </span> Row(s) <b style="color:red">${
                    res.data.error_rows
                  }</b> weren't added. Please check your rows because, these record(s) already exist and load those rows with a new excel
                  </span>`,
                  icon: `${
                    res.data.success_added_data == 0 ? "warning" : "success"
                  }`,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

                self.securityCreateMessage();
                self.SecurityModalLoading = false;
                self.securityCreateModal = false;
                self.showBondModal = false;
                self.resetSecurityModal();
                self.getAllEntries();
              })
              .catch((error) => {
                self.$swal({
                  title: "Error!",
                  text:
                    "Oops, Something went wrong while loading excel, please try again",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

                console.log(error);
              });
          } else {
            self.$swal({
              title: "Error!",
              text:
                "Oops, This excel table headers not correct. Make sure the headers are correct",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },

    replaceExcelFile(e) {
      var files = e.target.files,
        f = files[0];
      var reader = new FileReader();
      const self = this;
      reader.onload = function(e) {
        var data = new Uint8Array(e.target.result);
        var workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];

        let worksheet = workbook.Sheets[sheetName];

        this.excelJson = XLSX.utils.sheet_to_json(worksheet, { defval: null });

        let controlExcelColumn = 0;
        let matchRegex = /(Payment Date|Interest|Principal|Total)/g;

        if (this.excelJson.length > 0 && this.excelJson != {}) {
          for (let x in this.excelJson[0]) {
            if (x.match(matchRegex)) {
              controlExcelColumn += 1;
            }
          }
          if (controlExcelColumn === 4) {
            this.excelJson.forEach((item) => {
              if (!item["Payment Date"].toString().includes("/")) {
                item["Payment Date"] = self.excelDateToJSDate(
                  item["Payment Date"]
                );
              }
            });
            store
              .dispatch("security/replaceBondData", {
                Ticker: self.selectedItemTicker,
                json: this.excelJson,
              })
              .then((res) => {
                self.$refs["file-input"].reset();

                self.$swal({
                  title: `${
                    res.data.success_added_data == 0 ? "warning" : "success"
                  }`,
                  //   text: `${res.data.success_added_data} data successfully added. But ${res.data.error_rows} rows didn't add. Please check your rows and load those rows with a new excel `,
                  html: `
                  <span style="${
                    res.data.success_added_data == 0 ? "display:none" : ""
                  }">${
                    res.data.success_added_data
                  } data successfully added.</span>
                  <br>
                  <span style="${
                    res.data.error_rows.length > 0 ? "" : "display:none"
                  }">
                  <span style="${
                    res.data.success_added_data == 0 ? "display:none" : ""
                  }">But  </span> Row(s) <b style="color:red">${
                    res.data.error_rows
                  }</b> weren't added. Please check your rows because, these record(s) already exist and load those rows with a new excel
                  </span>`,
                  icon: `${
                    res.data.success_added_data == 0 ? "warning" : "success"
                  }`,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

                self.securityCreateMessage();

                self.showPaymentsModal = false;
                self.selectedItemTicker = null;
                self.getAllEntries();
              })
              .catch((error) => {
                self.$swal({
                  title: "Error!",
                  text:
                    "Oops, Something went wrong while loading excel, please try again",
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });

                console.log(error);
              });
          } else {
            self.$swal({
              title: "Error!",
              text:
                "Oops, This excel table headers not correct. Make sure the headers are correct",
              icon: "error",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
        }
      };
      reader.readAsArrayBuffer(f);
    },

    excelDateToJSDate(serial) {
      var utc_days = Math.floor(serial - 25569);
      var utc_value = utc_days * 86400;
      var date_info = new Date(utc_value * 1000);
      return (
        date_info.getDate() +
        "-" +
        (date_info.getMonth() + 1) +
        "-" +
        date_info.getFullYear()
      );
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes("to")) {
            this.filterDateStart = this.rangeDate.split(" to ")[0];
            this.filterDateEnd = this.rangeDate.split(" to ")[1];

            this.selectedEvents();
          }
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          if (
            _.filter(val, {
              VerifiedBy: null,
              VerificationTime: null,
              VerificationDate: null,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Verify",
              title: this.$t("multiCheckBoxTitles.verify"),
              icon: "CheckSquareIcon",
              color: "green",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style>
#securityModal .modal-header .modal-title {
  color: white !important;
}

.verifiedRowColor {
  background-color: #00ff7f !important;
}
.table-verified {
  background-color: #00ff7f !important;
}

.badge.badge-light-success {
  background-color: rgba(255, 255, 255, 0.5);
}

.ID_background {
  background-color: gainsboro !important;
}
</style>

<style scoped>
.padding_08 {
  padding: 0.7rem !important;
}

.other-col {
  z-index: 0;
}

#table-container {
  display: flex;

  flex-wrap: nowrap;

  overflow-x: auto;
}

#table-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.compact_card_title >>> .card-title {
  font-weight: 600 !important;
  font-size: 1rem !important;
  margin-bottom: 1rem !important;
  text-decoration: underline !important;
}

.compact_page_card_title >>> .card-title {
  font-weight: 500 !important;
  font-size: 1.285rem !important;
  margin-bottom: 0.6rem !important;
  text-decoration: none !important;
}
</style>

<style>
.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

.b-table-sticky-column:last-child {
  right: 0;
}

.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}

.b-table-sticky-header {
  max-height: unset !important;
  height: inherit !important;
}
.hide-rows thead tr[role="row"] {
  display: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 73, 73);
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>

<!-- compact design scss code -->
<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// .v-select::v-deep .vs__selected {

//     position: relative !important;

//   }

// trade controls buttons
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.flatpickr-input {
  max-height: 30.66px !important;
}
</style>
